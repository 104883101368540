import { template as template_28360d9bdb75460cb5eba969759858e0 } from "@ember/template-compiler";
const WelcomeHeader = template_28360d9bdb75460cb5eba969759858e0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
